import "./location-usa.scss"

import { graphql } from 'gatsby'
import * as React from "react"
import FindDreamJob from "../../components/careers/finddreamjob"
import JobOpenings from "../../components/careers/jobopenings"
import LocationHeader from "../../components/careers/locationheader"
import WhyBTSLocations from "../../components/careers/whybtslocations"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo"

export const query = graphql`
  query($language: String!) {
    allContentfulJobs(
      filter: { locations: { elemMatch: { country: { slug: { eq: "usa" }, node_locale: { eq: "en-US" }}}}}
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        id
        jobSlug
        title
        updatedAt(formatString: "MM/DD/YY")
        remote
        office
        urgent
        description {
          description
        }
        locations {
          id
          slug
          name
          shortName
        }
      }
    }
 locales: allLocale(
      filter: { ns: { in: ["common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

const LocationUSAPage = ({ data }) => {
    const pageTitle = "Careers - USA"
    const pageDescription = "Get to Know Our USA Offices"
    const lang = 'EN'
    const meta = []

    const sanFrancisco = {
        name: "San Francisco, California",
        text: "Blue Trail Software was born in the foggy city, the heart of planet software. We are proud to call San Francisco our home; the Bay Area sets the standard in terms of development practices, trends, and standards. It is also home of the most demanding clients, and our goal is to provide them with teams of developers who can sustain the pace of the best developers of the Valley.",
        secondText: "As per our birthplace, we are an equal opportunity employer, a diverse company by design, and committed to continuous change."
    }

    const elgin = {
        name: "Elgin, IL",
        text: "While our heart remains in San Francisco, according to the popular folk song, Illinois has always played an important role in our company, for the many connections we have there, being with clients, universities, and talented professionals. We have a base in Elgin, 35 miles northwest of Chicago, along the Fox river.",
    }

    const [cityName, setCityName] = React.useState(sanFrancisco.name);
    const [cityText, setCityText] = React.useState(sanFrancisco.text);
    const [secondText, setSecondText] = React.useState(sanFrancisco.secondText);

    const breadcrumbMap = [
        { title: "Careers", url: "/careers/" },
        { title: "Locations", url: "/careers/locations/" },
        { title: "USA", url: "#" }
    ]

    const location = {
        title: "USA",
        cities: ["San Francisco, California", "Elgin, IL"],
        sectionClassName: "section-location-usa",
        modalClassName: "modal-location-usa"
    }

    var {
        nodes: jobs
    } = data.allContentfulJobs;

    const cities = location.cities.map((name, index) =>
        <div hidden={name === cityName} key={index} className="list-item" onClick={() => setCity(name)}>{name}</div>
    );

    const jobsList = jobs.map(({ jobSlug, title, locations, description, remote, relocation, office, urgent }) => (
        {
            title: title,
            location: locations.map((item) => (item["shortName"])).join(" / "),
            url: "/careers/jobs/" + jobSlug + "/",
            urgent: urgent,
            tags: { "remote": remote, "office": office, "relocation": relocation },
            introduction: description ? description.description.slice(0, 220) + "..." : ""
        }
    ));

    const setCity = (name) => {
        setCityName(name);
        switch (name) {
            case "San Francisco, California":
                setCityText(sanFrancisco.text);
                setSecondText(sanFrancisco.secondText);
                break;
            case "Elgin, IL":
                setCityText(elgin.text);
                setSecondText('');
                break;
            default:
                break;
        }
    }

    return (
        <>
            <Layout breadcrumbMap={breadcrumbMap}>
                <div className="max-container-pages">
                    <Seo title={pageTitle} description={pageDescription} lang={lang} meta={meta} />
                    <LocationHeader location={location} city={cityName}>
                        <span className="city-title">{cityName}</span>
                        <p className="city-text">
                            {cityText}
                        </p>

                        {secondText &&
                            <p>{secondText}</p>
                        }
                        <div className="other-locations">
                            <h5 className="other-locations-title">Other offices in USA</h5>
                            <div className="other-locations-content">
                                {cities}
                            </div>
                        </div>
                    </LocationHeader>
                    <WhyBTSLocations reverse={true}></WhyBTSLocations>
                    <JobOpenings jobs={jobsList} search="USA"></JobOpenings>
                    <FindDreamJob></FindDreamJob>
                </div>
            </Layout>
        </>
    )
}

export default LocationUSAPage
